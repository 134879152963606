import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import * as React from "react"
import * as R from "ramda"
import Layout from "../components/layout"
import { IArtist } from "../types/models"
import ReleaseItem from "../components/releaseItem"
import Seo from "../components/seo"
import "../styles/artistDetail.scss"
import NotFoundPage from "../pages/404"

export default ({ data, pageContext }: any) => {
  const intl = useIntl()

  let artist: IArtist | null = null

  let currentWithoutLocaleFilter: IArtist[] = React.useMemo<IArtist[]>(
    () =>
      R.pipe(
        R.pathOr([], ["allStrapiArtist", "nodes"]),
        R.filter(R.propEq("slug", pageContext.slug))
      )(data),
    [pageContext.slug]
  )
  const otherLocal = R.without<string>([intl.locale])(["en", "zh", "ja"])
  artist =
    (R.find(R.propEq("locale", intl.locale))(
      currentWithoutLocaleFilter
    ) as IArtist) ||
    (R.find(R.propEq("locale", otherLocal[0]))(
      currentWithoutLocaleFilter
    ) as IArtist) ||
    (R.find(R.propEq("locale", otherLocal[1]))(
      currentWithoutLocaleFilter
    ) as IArtist)

  if (!artist) {
    return (
      <Layout>
        <NotFoundPage />
      </Layout>
    )
  }

  const release = artist.releases.map(release => {
    const ats = R.pipe(
      R.pathOr([], ["allStrapiArtist", "nodes"]),
      R.filter((i: IArtist) => {
        return (i.releases || []).some((r: any) => r.id === release.id)
      })
    )(data)
    return {
      ...release,
      artists: ats,
    }
  })

  return (
    <>
      <Seo title={artist?.name} />
      <article className="flex artist-detail">
        <aside className="hidden md:block">
          <img
            src={`${artist?.avatar?.url}?x-oss-process=image/resize,w_1200`}
            alt={artist?.name}
          />
        </aside>
        <section className="artist-content">
          <div className="artist-header inline-flex md:flex flex-col md:flex-row">
            <div className="flex-1">
              <h1 className="PPG-Bold">{artist?.name}</h1>
              {/* <div className="bio ABC">
                {artist?.city ? (
                  <span>
                    {artist?.city}
                  </span>
                ) : null}
              </div>
                {artist?.birth_date ? <span>{artist?.birth_date}</span> : null}
              </div> */}
              <div className="bio ABC">{artist?.bio}</div>
              <div className="flex gap-2 md:gap-4 flex-wrap mt-2">
                {!!artist?.twitter_url && (
                  <a
                    target="_blank"
                    href={artist?.twitter_url}
                    className="hem-external-link"
                  >
                    Twitter
                  </a>
                )}
                {/* {!!artist?.bandcamp_url && (
                  <a
                    target="_blank"
                    href={artist?.bandcamp_url}
                    className="hem-external-link"
                  >
                    Bandcamp
                  </a>
                )} */}
                {!!artist?.youtube_url && (
                  <a
                    target="_blank"
                    href={artist?.youtube_url}
                    className="hem-external-link"
                  >
                    YouTube
                  </a>
                )}
                {!!artist?.spotify_url && (
                  <a
                    target="_blank"
                    href={artist?.spotify_url}
                    className="hem-external-link"
                  >
                    Spotify
                  </a>
                )}
                {!!artist?.weibo_url && (
                  <a
                    target="_blank"
                    href={artist?.weibo_url}
                    className="hem-external-link"
                  >
                    Weibo
                  </a>
                )}
                {!!artist?.instagram_url && (
                  <a
                    target="_blank"
                    href={artist?.instagram_url}
                    className="hem-external-link"
                  >
                    Instagram
                  </a>
                )}
              </div>
            </div>
            <div className="artist-intro flex-1 pl-1 ABC pt-10 md:pt-0">
              {artist?.intro?.split("\n").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
          <div className="block md:hidden w-full">
            <img
              src={`${artist?.avatar?.url}?x-oss-process=image/resize,w_1200`}
              alt={artist?.name}
            />
          </div>
          <div
            className={`grid grid-cols-1 md:grid-cols-2 artist-release-list ${
              release.length < 2 ? "dense-list" : ""
            }`}
          >
            {release.map((release, index) => (
              <section
                key={release.id}
                className={`flex items-start justify-center index-grid-item`}
              >
                <ReleaseItem key={release.slug} data={release} />
              </section>
            ))}
          </div>
        </section>
      </article>
    </>
  )
}

export const pageQuery = graphql`
  query Artist {
    allStrapiArtist(sort: { order: DESC, fields: created_at }) {
      nodes {
        name
        id
        locale
        bio
        slug
        releases {
          cover {
            url
            name
          }
          slug
          release_type
          serial_number
          id
          name
        }
        avatar {
          url
        }
        intro
        twitter_url
        weibo_url
        youtube_url
        instagram_url
        spotify_url
      }
    }
  }
`
